import request from "@/utils/request.js"

export function getSpecialistList() {
	return request({
		url: '/clinic/specialist/list',
		method: 'get'
	})
}


export function getScheduleListByDate(query) {
	return request({
		url: '/clinic/schedule/ListByDate',
		method: 'get',
		params: query
	})
}

export function getScheduleListVary(query) {
	return request({
		url: '/clinic/schedule/ListVary',
		method: 'get',
		params: query
	})
}

export function getScheduleListGeneral(query) {
	return request({
		url: '/clinic/schedule/ListByGeneral',
		method: 'get',
		params: query
	})
}


// 出诊次数统计
export function StatisticClinicTimesByDoctor(query) {
	return request({
		method: 'get',
		url: '/clinic/schedule/StatisticClinicTimesByDoctor',
		params: query
	})
}

export function updateRemark(query) {
	return request({
		method: 'post',
		url: '/clinic/schedule/UpdateRemark',
		params: query
	})
}
export function del(query) {
	return request({
		method: 'get',
		url: '/clinic/schedule/Delete',
		params: query
	})
}