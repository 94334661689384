import Layout from '@/layout'

const clinicRouter = {
  path: '/clinic',
  name: 'Clinic',
  component: Layout,
  meta: { title: '出诊信息', icon: 'icon-clinic' },
  children: [{
    path: 'schedule',
    name: 'Schedule',
    component: () => import('@/views/clinic/schedule'),
    meta: { title: '出诊列表', icon: 'icon-clinic-order' },
  },
  {
    path: 'calendar',
    name: 'Calendar',
    component: () => import('@/views/clinic/calendar'),
    meta: { title: '出诊日历', icon: 'icon-clinic-calendar' },
  },]
}

export default clinicRouter
