import Layout from '@/layout'

const specialistRouter = {
  path: '/specialist',
  name: 'Specialist',
  component: Layout,
  meta: { title: '专家管理', icon: 'icon-specialist' },
  children: [{
    path: 'list',
    name: 'List',
    hidden: false,
    component: () => import('@/views/specialist/list'),
    meta: { title: '专家列表', icon: 'icon-specialist-order' },
  }, {
    path: 'detail',
    name: 'Detail',
    hidden: false,
    component: () => import('@/views/specialist/detail'),
    meta: { title: '业务数据', icon: 'icon-specialist-detail' },
  }, {
    path: 'introduce',
    name: 'Introduce',
    hidden: false,
    component: () => import('@/views/specialist/introduce'),
    meta: { title: '专家资料', icon: 'icon-introduce', roles: ["Admin","Director"] },
  },]
}

export default specialistRouter
