import Layout from '@/layout'

const dataRouter = {
  path: '/data',
  name: 'Data',
  component: Layout,
  meta: { title: '业务数据(中心)', icon: 'icon-data' },
  children: [{
    path: 'hospital',
    name: 'DataByHospital',
    component: () => import('@/views/data/hospital'),
    meta: { title: '按医院统计', icon: 'icon-hospital' },
  }, {
    path: 'department',
    name: 'DataByDepartment',
    component: () => import('@/views/data/department'),
    meta: { title: '按科室统计', icon: 'icon-department' },
  },
  {
    path: 'doctor',
    name: 'DataByDoctor',
    component: () => import('@/views/data/doctor'),
    meta: { title: '按医生统计', icon: 'icon-doctor' },
  },
  ]
}

export default dataRouter
