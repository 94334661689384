import store from '../store'
import router from '../router'
import { Message } from 'element-ui'
import { getToken } from '@/utils/auth' // get token from cookie
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style

const whiteList = ['/login']
NProgress.configure({ showSpinner: true })

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  const hasToken = getToken()
  if (hasToken) {
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      try {
        const { roles } = await store.dispatch('user/getInfo')
        // // let roles = store.state.user.roles;
        await store.dispatch('permission/generateRoutes', roles.split(";"));
        next()
        NProgress.done();
      }
      catch (error) {
        await store.dispatch('user/resetToken')
        Message.error(error || '发生未知错误')
        next(`/login?redirect=${to.path}`)
        NProgress.done()
      }
    }
  }
  else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
