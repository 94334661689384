import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

/* Router Modules */
import rootRouter from './modules/home'
import specialistRouter from './modules/specialist'
import clinicRouter from './modules/clinic'
import dataRouter from './modules/data'
import qualityRouter from './modules/quality'
import statisticRouter from './modules/statistic'
import varyRouter from './modules/vary'

export const constantRoutes = [

  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/error/404'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/error/401'),
    hidden: true
  },
  // router文件太长，将其拆分成子文件
  rootRouter,
  specialistRouter,
  clinicRouter,
  qualityRouter,
  varyRouter,
  dataRouter,
  statisticRouter,


  // 404 page must be placed at the end !!!
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]

const createRouter = () => new VueRouter({
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

export default router

