import { login, logout, getInfo } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { resetRouter } from '@/router'

const getDefaultState = () => {
  return {
    token: getToken(),
    userCode: '',
    userName: '',
    roles: '',
    department: null,
    relation: ''
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_ID: (state, id) => {
    state.userCode = id
  },
  SET_NAME: (state, name) => {
    state.userName = name
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_DEPARTMENT: (state, department) => {
    if (!department || department == '') return;
    try {
      state.department = JSON.parse(department.replace(/\'/g, '"'));
    }
    catch (e) {
      console.log(e);
    }
  },
  SET_RELATION: (state, relation) => {
    state.relation = relation ? relation : '';
  }
}

const actions = {
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ UserCode: username.trim(), PassWord: password }).then(response => {
        const { data } = response
        commit('SET_TOKEN', data.token)
        setToken(data.token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo().then(response => {
        const { data } = response
        if (!data) {
          return reject('用户认证失败，请重新登录.')
        }
        const { id, username, roles, department, relation } = data
        commit('SET_ID', id)
        commit('SET_NAME', username)
        commit('SET_ROLES', roles)
        commit('SET_DEPARTMENT', department)
        commit('SET_RELATION', relation)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout().then(() => {
        removeToken() // must remove  token  first
        resetRouter()
        commit('RESET_STATE')
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

