<script>
export default {
    name: "MenuItem",
    functional: true,
    props: {
        icon: {
            type: String,
            default: "",
        },
        title: {
            type: String,
            default: "",
        },
    },
    render(h, context) {
        const { icon, title } = context.props;
        const vnodes = [];
        if (icon) {
            if (icon.includes("el-icon")) {
                vnodes.push(<i class={[icon, "sub-el-icon"]} />);
            } else {
                // vnodes.push(<i icon-class={icon} />);
                vnodes.push(<i class={["iconfont", icon, "sub-el-icon", "sub-iconfont"]} />);
            }
        }

        if (title) {
            vnodes.push(<span solt="title">{title}</span>);
        }
        return vnodes;
    },
};
</script>

<style scoped>
.sub-el-icon {
    color: currentColor;
    width: 1em;
    height: 1em;
}

.sub-iconfont {
    font-size: 20px;
    color: #eee;
}
</style>
