import Layout from '@/layout'

const varyRouter = {
  path: '/vary',
  name: 'Vary',
  component: Layout,
  meta: { title: '停改诊统计', icon: 'icon-quality' },
  children: [{
    path: 'province',
    name: 'Province',
    component: () => import('@/views/vary/province'),
    meta: { title: '沪杭专家统计', icon: 'icon-hospital' },
  },
  {
    path: 'native',
    name: 'Native',
    component: () => import('@/views/vary/native'),
    meta: { title: '本院专家统计', icon: 'icon-doctor' },
  },
  {
    path: 'general',
    name: 'General',
    component: () => import('@/views/vary/general'),
    meta: { title: '专科门诊统计', icon: 'icon-doctor' },
  },
  ]
}

export default varyRouter
