import Layout from '@/layout'

const qualityRouter = {
  path: '/quality',
  name: 'Quality',
  component: Layout,
  meta: { title: '质量控制', icon: 'icon-quality' },
  children: [{
    path: 'hospital',
    name: 'QualityByHospital',
    component: () => import('@/views/quality/hospital'),
    meta: { title: '按医院统计', icon: 'icon-hospital' },
  },
  {
    path: 'doctor',
    name: 'QualityByDoctor',
    component: () => import('@/views/quality/doctor'),
    meta: { title: '按医生统计', icon: 'icon-doctor' },
  },
  {
    path: 'ai',
    name: 'QualityAI',
    component: () => import('@/views/quality/ai'),
    meta: { title: 'AI病历质控', icon: 'icon-doctor' },
  },
  ]
}

export default qualityRouter
