import { getSpecialistList } from '@/api/clinic'


const state = {}

const mutations = {}

const actions = {
    getSpecialistList({ commit, state }) {
        return new Promise((resolve, reject) => {
            getSpecialistList().then(response => {
                const { data } = response
                if (!data) {
                    return reject('用户认证失败，请重新登录.')
                }
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

