import Layout from '@/layout'

const rootRouter = {
  path: '/',
  component: Layout,
  redirect: '/home',
  children: [{
    path: 'home',
    name: 'Home',
    component: () => import('@/views/home/index'),
    meta: {
      title: '数据主页',
      icon: 'icon-home'
      // icon: 'el-icon-s-home'
    }
  }]
}

export default rootRouter
