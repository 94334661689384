import Layout from '@/layout'

const statisticRouter = {
  path: '/statistic',
  name: 'Statistic',
  component: Layout,
  meta: { title: '统计分析(全部)', icon: 'icon-statistic' },
  children: [{
    path: 'hospital',
    name: 'StatisticByHospital',
    component: () => import('@/views/statistic/hospital'),
    meta: { title: '按医院统计', icon: 'icon-hospital' },
  }, {
    path: 'department',
    name: 'StatisticByDepartment',
    component: () => import('@/views/statistic/department'),
    meta: { title: '按科室统计', icon: 'icon-department' },
  },
  {
    path: 'doctor',
    name: 'StatisticByDoctor',
    component: () => import('@/views/statistic/doctor'),
    meta: { title: '按医生统计', icon: 'icon-doctor' },
  },
  ]
}

export default statisticRouter
