import request from "@/utils/request.js"

export function login(data) {
  return request({
    url: '/user/Login',
    method: 'post',
    data
  })
}

export function getInfo() {
  return request({
    url: '/user/GetUserInfo',
    method: 'get'
  })
}

export function logout() {
  return request({
    url: '/user/Logout',
    method: 'post'
  })
}


export function changePassWord(query) {
  return request({
    method: 'post',
    url: '/user/ChangePassWord',
    params: query
  })
}

export function getUserList() {
	return request({
		url: '/user/list',
		method: 'get'
	})
}

export function getDepartmentList() {
	return request({
		url: '/user/department/list',
		method: 'get'
	})
}